/* Initially hidden state */
.popup-hidden {
  display: none;
}

/* Class to show the popup */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -45%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/* Apply the animation to the popup */
.popup-open {
  display: block;
  position: absolute;
  top: 40%;
  left: 50%;
  /* width: 100%; */
  height: 48%;
  transform: translate(-50%, -50%);
  background-color: rgb(61, 59, 59);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: fadeIn 0.5s ease-in-out;
}

/* Class to hide the popup */
.popup-close {
  display: none;
}
.tt_container {
 
  height: 200px;
}

.selwrap-container{
  height: 600vh;
  margin-top: -21px;
  /* overflow-y: 'scroll'; */ 
  /* margin-top: -30px; */
}
.card-view {
  margin-top: -2%;
}
.row {
  margin-top: -0.05%  ;
}
.values-container{
  width: 100px;
  height: 50px;
}
.beats-table-container {
  margin: 20px;
  font-family: Arial, sans-serif;
  border-radius: 5px; /* Rounded corners */
  overflow: hidden; /* Prevents overflow of rounded corners */
}

.beats-table {
  width: 100%;
  border-collapse: collapse; /* Merge borders */
}

.beats-table-header {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
}

.beats-table-cell {
  border: 1px solid #eae3e3; /* Cell border */
  padding: 12px; /* Cell padding */
  text-align: center; /* Center text */
}

.beats-table-row:nth-child(even) {
  background-color: #f2f2f2; /* Light gray for even rows */
}

.beats-table-row:hover {
  background-color: #743131; /* Darker gray on hover */
}

.beats-table-cell {
  font-size: 16px; /* Slightly larger text */
}
.beats-value-container{
  margin-left: 21px;
}
.finding_tab {
  padding-top: 55px;
}
.beats-container{
  margin-bottom: -50px;
}
/* Modal background */
/* .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  /* Modal box */
  /* .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  } */
  
  /* Modal header */
  /* .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */
  
  /* Close button */
  /* .close-btn {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }
   */
  /* Modal footer (for buttons) */
  /* .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
  } */
  .subject-textarea{
    width: 100%;
    color: black;
    height: 100px;
  }
  /* General table styling */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  text-align: left;  /* Default left alignment */
  border: none;  /* Optional: borders around cells */
}

/* Example of specific column width control */
th:nth-child(1),
td:nth-child(1) {
  width: 1%;
}

th:nth-child(2),
td:nth-child(2) {
  width: 2%;
}

th:nth-child(3),
td:nth-child(3) {
  width: 7%;
}

/* Aligning columns as needed */
th:nth-child(4),
td:nth-child(4) {
  width: 10%;
}
th:nth-child(5){
  text-align: center;
}
th:nth-child(6){
  text-align: center;
}



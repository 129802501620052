.navbar ul.navbar-right.top-nav > li > ul {
    display: none; /* Initially hide the dropdown */
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.navbar ul.navbar-right.top-nav > li:hover > ul {
    display: block; /* Show the dropdown on hover */
    opacity: 1;
}

.navbar ul.navbar-right.top-nav > li {
    position: relative; /* Ensure the dropdown is positioned relative to its parent */
}

.navbar ul.navbar-right.top-nav > li > ul.dropdown-menu {
    position: absolute; /* Make sure the dropdown doesn't affect the layout */
    right: 0; /* Align it to the right */
    top: 100%; /* Position it below the navbar item */
    z-index: 1000; /* Make sure it appears above other elements */
}
.navbar{
    background-color: #24333e !important;
}
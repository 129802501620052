.rdtPicker table {
    width: 100%;
    margin: 0;
    color: black;
}
.low-efficency{
background-color: #e62012;
color:black
}
.high-efficency{
background-color: #08990a;
color: black;
}
.average-efficency{
background-color: #faff6c;
color: black;
}
.medium-efficency{
  background-color: #dce31e;
  color: black;
}
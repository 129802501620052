.serialno_button{
    color: white;
    background-color: transparent;
    border: none;

}
/* Hide the serial_no column */
table td:nth-child(7),
table th:nth-child(7) {
  display: none;
}
.col-sm-12 {
margin-top: 10px;}
.wrapper.theme-1-active .navbar.navbar-inverse{
  background-color: #24333e;
  border: none;
}
.wrapper.theme-1-active .fixed-sidebar-left .side-nav {
  background: #24333e;
  border: none;
}
.wrapper.theme-1-active .fixed-sidebar-left {
border: none;
}

.table th:nth-child(7) {
  text-align: center; /* Center align the 6th column */
}
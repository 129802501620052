
.notfound-container {
    /* background-color: white; */
    text-align: center;
    margin-top: 15%;
    
  }
  
  h1 {
    font-size: 80px;
    color: white;
  }
  
  h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .back-home-link {
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    color: white;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .back-home-link:hover {
    background-color: #0056b3;
  }
  
/* Hide the scrollbar for Chrome, Safari, and Edge */
body::-webkit-scrollbar {
    display: none;
}

/* Hide the scrollbar for IE and Firefox */
body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;     /* Firefox */
}
.container-fluid {
    margin-top: 2%;
}
